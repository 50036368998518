@import "../../global.scss";


.menu {
    width: 300px;
    height: 100vh;
    background-color: $mainLightColor;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 2;
    color: $mainDarkColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1.5s ease;

    &.active {
        left: 0;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 25px;
        font-weight: 300;
        color: $mainDarkColor;
        width: 60%;

        li {
            margin-bottom: 25px;
            a {
                font-size: inherit;
                color: inherit;
                text-decoration: none;
            }

            &:hover {
                font-weight: 500;
            }
        }
    }
}