@import "../../global.scss";

.portfolio {
    color: $mainLightColor;
    padding-top: 2.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 3rem;
        
        @include mobile {
            font-size: 20px;
        }
    }

    ul {
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;

        @include mobile {
            flex-wrap: wrap;
            justify-content: center;
            margin: 10px 0;
        }
    }
    .container {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @include mobile {
            width: 100%;

        }

        .item {
            // width: 350px;
            // height: 250px;
            width: 20vw;
            height: 22vh;
            border-radius: 20px;
            border: 1px solid rgb(150, 150, 150);
            margin: 20px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $mainDarkColor;
            position: relative;
            transition: all .5s ease;
            cursor: pointer;
            z-index: 0;

            @include mobile {
                width: 130px;
                height: 100px;
            }

            h3 {
                position: absolute;
                font-size: 20px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }

            &:hover {
                background-color: $mainLightColor;
                img {
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }
    }
}