@import "./global.scss";

.app {
    height: 100vh;
    width: 100%;
    background-color: $mainDarkColor;
    // background-image: url("https://wallpapercave.com/wp/wp4030805.jpg");
    background-image: url("./media/site-bg.webp");
    background-size: cover;

    .sections {
        width: 100%;
        height: calc(100vh - 70px);
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none; //firefox
        &::-webkit-scrollbar {
            display: none;
        }

        > * {
            width: 100vw;
            height: calc(100vh - 70px);
            scroll-snap-align: start;
        }
    }
}