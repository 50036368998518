@import "../../global.scss";

.modal-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99;

	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 100;
		background-color: $mainOverlayColor;
		backdrop-filter: blur(5px);
		-webkit-backdrop-filter: blur(5px);
	}
	.modal-box {
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: auto;
		max-height: 80%;
		width: 50%;
		// max-width: 70%;
		overflow-y: auto;
		background-color: $mainDarkColor;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
		z-index: 101;
		padding: 20px;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;

		@include mobile {
			width: 100%;
			height: 50vw;
			margin: 15px 0;
			border-radius: 10px;
			padding: 10px;
		}

		.close-modal {
			position: absolute;
			top: 5px;
			right: 10px;
			padding: 5px 7px;
			border-radius: 5px;
			cursor: pointer;
			border: none;
			background-color: rgba(0, 0, 0, 0);
			color: $mainLightColor;
			font-size: 1vw;

			@include mobile {
				top: 5px;
				right: 10px;
				border-radius: 3px;
				font-size: 5vw;
			}
		}

		.left {
			flex: 8;
			height: 90%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 2vh;
			margin-bottom: 2vh;

			@include mobile {
				overflow: hidden;
				margin-top: 0vh;
				margin-bottom: 0vh;
			}

			.leftContainer {
				width: 95%;
				height: 95%;
				display: flex;
				flex-direction: column;
				justify-content: center;

				@include mobile {
					width: 100%;
					height: 95%;
					margin: 5px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
				}

				.imgContainer {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					background-color: white;
					// background-color: $mainDarkColor;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 2vh;

					@include mobile {
						width: 30px;
						height: 30px;
						margin-bottom: none;
					}

					.icon {
						// background-color: black;
						color: rgb(5, 15, 131);
						width: 80%;
						height: auto;

						@include mobile {
							width: 20px;
						}
					}
				}
				h2 {
					font-size: 1.5vw;
					margin-bottom: 2vh;

					@include mobile {
						font-size: 5vw;
						margin-bottom: none;
					}
				}
				p {
					font-size: 1vw;
					font-weight: 300;
					margin-bottom: 2vh;

					@include mobile {
						display: none;
					}
				}
				.itemContainer {
					display: flex;
					align-items: center;

					.item {
						display: flex;
						align-items: center;
						margin-right: 30px;

						@include mobile {
							margin-right: 4vw;
						}

						.linked {
							text-decoration: none;
							display: flex;
							align-items: center;
							color: inherit;
							cursor: pointer;
						}
						.icon {
							font-size: 1.2vw;
							margin-right: 10px;

							@include mobile {
								font-size: 4vw;
							}
						}
						span {
							font-size: 0.9vw;
							font-weight: 400;
							text-decoration: none;

							@include mobile {
								font-size: 3vw;
							}
						}
					}
				}
			}
		}
		.right {
			flex: 4;
			height: 90%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;

			img {
				width: 95%;
				height: auto;
			}
		}
	}
}
