@import "../../global.scss";

.contact {
	color: $mainLightColor;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h1 {
		font-size: 3rem;
		text-align: center;
		color: $mainLightColor;
		margin-bottom: 1rem;

		@include mobile {
			font-size: 20px;
		}
	}
	.subtitle {
		color: $mainLightColor;
		text-align: center;
		font-size: 1.2rem;
		margin-bottom: 2rem;

		&.error {
			padding: 3rem;
		}
		&.sent {
			padding: 1rem;
			background-color: red;
		}
	}

	.contactContainer {
		display: flex;
		// width: 1200px;
		width: 50vw;
		border-radius: 8px;
		overflow: hidden;
		padding: 15px 10px 15px 10px;
		background-color: $mainOverlayColor;
		backdrop-filter: blur(5px);
		-webkit-backdrop-filter: blur(5px);
		-webkit-box-shadow: 0px 7px 18px 10px rgba(8, 8, 8, 1);
		-moz-box-shadow: 0px 7px 18px 10px rgba(8, 8, 8, 1);
		box-shadow: 0px 7px 18px 10px rgba(8, 8, 8, 1);

		@include mobile {
			width: 100%;
			padding: 0.8rem;
		}

		.leftContainer {
			flex: 0 1 40%;
			display: flex;
			flex-direction: column;
			border-radius: 8px;
			padding: 30px;
			overflow: hidden;
			background-color: rgba(0, 0, 171, 0.527);
			color: $mainLightColor;
			justify-content: space-evenly;

			@include mobile {
				display: none;
			}

			.contactTopline {
				h4 {
					font-size: 2rem;
					font-weight: 500;
					margin-top: 0;
					margin-bottom: 1.2rem;
				}

				p {
					font-size: 1.2rem;
					margin: 0;
					color: rgba(242, 242, 242, 0.8);
					font-weight: 300;
					margin-bottom: 1.8rem;
				}
			}

			.contactLinks {
				// height: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;

				.iconText {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					margin-bottom: 1rem;

					.icon {
						width: 25px;
						height: auto;
						margin-right: 0.8rem;
						color: $mainLightColor;
					}
					span {
						font-size: 1rem;
						font-weight: 300;
						color: $mainLightColor;
					}
					a {
						font-size: 1rem;
						font-weight: 300;
						text-decoration: none;
						color: $mainLightColor;
						cursor: pointer;
					}
				}
			}

			.socialMedia {
				display: flex;
				justify-content: space-between;
				width: 80%;
				margin-top: 1.8rem;
				padding-left: 2rem;

				.iconCircle {
					flex: 0 0 35px;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 35px;
					border-radius: 50%;
					transition: 0.3s all ease-in-out;

					&:hover {
						background: rgba(76, 91, 128, 1);
					}

					.icon {
						color: $mainLightColor;
						width: 25px;
						height: auto;
					}
				}
			}
		}

		.rightContainer {
			padding: 20px 40px 0px 40px;
			width: 100%;

			@include mobile {
				padding: 0.2rem;
			}

			.col {
				display: flex;
				width: 100%;
				flex: 0 0 100%;

				@include mobile {
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}

				&.user-msg {
					transition: 0.8s all ease-in-out;
				}
			}
			.form-group {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				flex: 0 1 100%;
				padding-right: 40px;

				@include mobile {
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding-right: 0;
					width: 100%;
				}

				label {
					font-size: 0.9rem;
					color: rgba(255, 255, 255, 0.6);
					margin-bottom: 0.5rem;

					@include mobile {
						margin-bottom: 0.1rem;
					}
				}
				input[type="text"],
				input[type="tel"],
				input[type="email"],
				textarea {
					background: transparent;
					border: none;
					border-bottom: 2px solid rgba(255, 255, 255, 0.4);
					padding: 5px;
					font-size: 1.1rem;
					font-weight: 200;
					cursor: pointer;
					color: white;
					transition: 0.3s all ease-in-out;
					margin-bottom: 2rem;

					@include mobile {
						margin-bottom: 0.8rem;
						padding: 2px;
						width: 200px;
					}

					&:focus {
						border: none;
						outline: none;
						border-bottom: 2px solid $mainLightColor;
					}
				}
				.message {
					@include mobile {
						width: 90%;
						font-size: 0.9rem;
					}
				}
				&:nth-child(2) {
					padding-right: 0;
				}

				#reply {
					font-size: 1.1rem;

					@include mobile {
						font-size: 1rem;
					}
				}

				#radioBtns {
					display: flex;
					justify-content: flex-start;
					margin-top: 1rem;
					margin-bottom: 2rem;

					@include mobile {
						justify-content: center;
						margin-bottom: 0.5rem;
					}

					.radioBtn {
						margin-right: 2rem;

						@include mobile {
							margin-right: 0.8rem;
						}
					}

					label {
						font-size: 1rem;
						margin-left: 0.5rem;

						@include mobile {
							font-size: 0.8rem;
						}
					}
					input[type="radio"]:checked + label {
						color: rgba(255, 255, 255, 1);
						font-weight: 600;
					}
				}

				&.solo {
					padding: 0;
				}
				&.right {
					align-items: flex-end;
				}
				button {
					width: 150px;
					height: 30px;
					cursor: pointer;
					background: $mainLightColor;
					color: $mainDarkColor;
					border: none;
					text-transform: uppercase;
					border-radius: 8px;

					@include mobile {
						width: 100px;
						height: 20px;
					}

					&:hover {
						color: blue;
						font-weight: 600;
						transition: 0.2s all ease-in-out;
					}

					&.inactive {
						background: $mainDarkColor;
						color: grey;
						transition: 0.3s all ease-in-out;
					}
				}
				.user-message {
					color: $mainLightColor;
					text-align: center;
					font-size: 1.2rem;
					border-radius: 8px;

					&.sent {
						padding: 0.2rem;
						margin-top: 0.5rem;
						background-color: rgba(0, 0, 171, 0.527) !important;
						transition: 0.8s all ease-in-out !important;
					}
					&.error {
						padding: 0.2rem;
						margin-top: 0.5rem;
						background-color: red !important;
						transition: 0.8s all ease-in-out !important;
					}
				}
			}
		}
	}
}
