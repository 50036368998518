$mainDarkColor: #2b2b2a;
$mainLightColor: #f2f2f2;
$mainOverlayColor: rgba(255, 255, 255, 0.2);

$width: 768px;

* {
    box-sizing: border-box;
}

@mixin mobile {
    @media (max-width: #{$width}){
        @content
    }
}